<script>
import Loader from "vue-element-loading";
import TimePicki from "@/components/timepicki.vue";
import api from "@/api";

export default {
  components: {
    Loader,
    TimePicki,
  },
  data() {
    return {
      loading: false,
      data: null,
      start_time_override: null,
      end_time_override: null,
      receipts: [],
      invoices: [],
      discount: null,
    };
  },
  methods: {
    approve(id) {
      const url = "/ScheduleItem/" + id + "/";
      const data = { active: 1, is_approval: 1 };
      api.Manager.axios.patch(url, data).then(() => {
        this.getEvent();
      });
    },
    applyDiscountToLatestInvoice() {
      var url = `/api/schedules/${this.scheduleID}/apply_discount/`;
      const data = { discount: this.discount };
      api.axios.post(url, data).then(() => {
        this.getReceiptsAndInvoicesForSchedule();
      });
    },
    getEvent() {
      api.Manager.axios.get(this.url).then((response) => {
        const data = response.data;
        this.data = data;
        this.start_time_override = data.start_time_readable;
        this.end_time_override = data.end_time_readable;
      });
    },
    getTitle(ev) {
      if (ev.title && ev.title !== "") {
        return ev.title;
      }
      if (ev.group) {
        return ev.group.name;
      }
    },
    editEvent() {
      var p = this;
      var d = {
        start_time:
          p.data.date_string + "T" + p.data.start_time_readable + ":00",
        end_time: p.data.date_string + "T" + p.data.end_time_readable + ":00",
        requested_amount: p.data.requested_amount,
      };
      this.loading = true;
      api.Manager.axios
        .patch(this.url, d)
        .then(() => {
          this.getEvent();
          alert("Saved!");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancelEvent() {
      const data = { cancelled: 1, seen_by_manager: 1, is_approval: 1 };
      this.loading = true;
      api.Manager.axios
        .patch(this.url, data)
        .then(() => {
          this.getEvent();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getReceiptsAndInvoicesForSchedule() {
      var url = "/getReceiptsAndInvoicesForSchedule/" + this.scheduleID;
      api.Manager.axios.get(url).then((response) => {
        const data = response.data;
        this.receipts.splice();
        this.receipts = data.receipts;
        this.invoices.splice();
        this.invoices = data.invoices;
      });
    },
    setStartTime(time) {
      this.data.start_time_readable = time.time;
    },
    setEndTime(time) {
      this.data.end_time_readable = time.time;
    },
    isPast(val) {
      return val === "1";
    },
  },
  computed: {
    booker() {
      const fields = ["name", "email"];
      for (const field of fields) {
        const val = this.data.booker[field];
        if (val) {
          return val;
        }
      }
      return "";
    },
    url() {
      var url = "/ScheduleItem/" + this.scheduleID + "/";
      return url;
    },
    scheduleID() {
      return this.$route.params.ID;
    },
  },
  created() {
    this.loading = true;
    const promises = [];
    promises.push(this.getEvent());
    promises.push(this.getReceiptsAndInvoicesForSchedule());
    Promise.allSettled(promises).then(() => {
      this.loading = false;
    });
  },
};
</script>
<template>
  <div id="ScheduleItem" class="container">
    <Loader :active="loading" />

    <div class="row">
      <div class="col text-center">
        <h3>Booking Data</h3>
      </div>
    </div>

    <div class="row justify-content-center">
      <div
        class="data-fields px-2 col-11 col-md-8 text-center"
        v-if="data"
        id="event"
      >
        <div class="form-group row booker" v-if="data.booker">
          <div class="col-4 col-md-3">
            <label for="title">Booked by </label>
          </div>
          <div class="col">
            <router-link
              :to="{
                name: 'SingleClients',
                params: { ID: data.booker.id },
              }"
            >
              <i class="fas fa-user"></i> {{ booker }}
            </router-link>
          </div>
        </div>

        <div class="form-group row" v-if="data.group">
          <div class="col-4 col-md-3">
            <label for="title">Group</label>
          </div>
          <div class="col">
            {{ data.group.name }}
          </div>
        </div>

        <div class="form-group row" v-else>
          <div class="col-4 col-md-3">
            <label for="title">Title</label>
          </div>
          <div class="col">'{{ data.title }}'</div>
        </div>

        <div v-if="data.title" class="form-group row">
          <div class="col-4 col-md-3">
            <label for="title">Title</label>
          </div>
          <div class="col">
            <input
              v-model="data.title"
              type="text"
              class="form-control"
              id="title"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-4 col-md-3">
            <label for="start_time">Date</label>
          </div>
          <div class="col">
            {{ data.date_readable }}
          </div>
        </div>

        <div class="form-group row">
          <div class="col-4 col-md-3">
            <label for="start_time">Start Time</label>
          </div>
          <div class="col">
            <time-picki
              id="start_time_i"
              :show_30s="true"
              :disabled="isPast(data.is_past) || data.cancelled"
              :override="start_time_override"
              @change="setStartTime"
            ></time-picki>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-4 col-md-3">
            <label for="end_time">End Time</label>
          </div>
          <div class="col">
            <time-picki
              id="end_time_i"
              :show_30s="true"
              :disabled="isPast(data.is_past) || data.cancelled"
              :override="end_time_override"
              @change="setEndTime"
            ></time-picki>
          </div>
        </div>

        <div
          class="form-group row text-left"
          id="bookingNotes"
          v-if="data.notes"
        >
          <div class="col-4 col-md-3">
            <label for="notes">Notes:</label>
          </div>
          <div class="col">
            <div class="bg-secondary text-white p-2">
              {{ data.notes }}
            </div>
          </div>
        </div>

        <!-- <div class="form-group row"> -->
        <!--     <div class="col-4 col-md-3"> -->
        <!--         <label for="discount">Amount</label> -->
        <!--     </div> -->
        <!--     <div class="col"> -->
        <!--         <input id="requested_amount" class="form-control" -->
        <!--         :disabled="isPast(data.is_past) && !data.can_edit" v-model="data.requested_amount" type="text" placeholder="Requested Amount"> -->
        <!--     </div> -->
        <!-- </div> -->

        <div class="form-group row font-weight-bold" v-if="data.cancelled">
          <div class="offset-4 offset-md-3 col">
            <span class="text-white bg-danger d-block p-2">
              <i class="fas fa-exclamation-triangle"></i> Cancelled
            </span>
          </div>
        </div>

        <div class="form-group row my-1" v-else-if="!data.active">
          <div
            class="col p-3 text-center text-danger"
            v-if="isPast(data.is_past)"
          >
            <i class="fas fa-times text-danger"></i>
            <i class="m-1 d-block-inline">Booking was not approved.</i>
          </div>
          <template v-else>
            <div class="col-4 col-md-3">
              <label class="fw-bold" for="discount">Approval Required</label>
            </div>
            <div class="col">
              <button class="btn btn-success" @click="approve(data.id)">
                Approve
              </button>
            </div>
          </template>
        </div>

        <div class="form-group row" v-else>
          <div class="col-4 col-md-3"></div>
          <div class="col font-italics">
            <span class="text-success" v-if="data.active">
              <i class="fas fa-check"></i>
              <i>Booking approved.</i>
            </span>
            <span class="text-danger" v-else-if="data.cancelled">
              <i class="fas fa-times text-danger"></i>
              <i>Booking cancelled.</i>
            </span>
          </div>
        </div>

        <div
          class="form-group row my-2"
          v-if="!data.cancelled && !isPast(data.is_past)"
        >
          <div class="col">
            <div class="row">
              <div class="col">
                <button class="btn btn-success" @click.prevent="editEvent">
                  Save Changes
                </button>
              </div>
              <div class="col" v-if="!data.cancelled">
                <button class="btn btn-danger" @click.prevent="cancelEvent">
                  Cancel Rehearsal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <InvoiceTable /> -->
    <!-- <ReceiptTable /> -->
  </div>
</template>

<style lang="scss" scoped>
#ScheduleItem {
  margin-top: 10px;
  &,
  button.btn,
  .form-control {
    font-size: 14px;
  }
}
#event {
  border: 1px solid #eee;
  border-radius: 3px;
  background: white;
  padding-top: 10px;
  .form-group {
    margin-bottom: 10px;
    text-align: left;
    &.booker {
      a {
        text-decoration: none;
        color: blue;
        background: none;
      }
    }
  }
}
section {
  background: #fafafa;
  padding: 15px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
