<script>
export default {
  name: "TimePicki",
  props: {
    id: { type: String },
    start: { type: Number },
    startDateTime: { type: String },
    end: { type: String },
    override: { type: String },
    show_30s: { type: Boolean },
    maxHours: { type: Number },
  },
  data() {
    return {
      time_values: [],
      start_int: 0,
      end_int: 24,
      inner_digits: "",
      inner_show_30s: true,
    };
  },
  methods: {
    getShortName(val) {
      const map = {
        "00:00": "Midnight",
        "12:00": "Noon",
      };
      const retVal = map[val];
      if (retVal) {
        return retVal;
      }
      return null;
    },
    readStart() {
      if (this.start && typeof this.start === "number") {
        if (this.start > -1 && this.start <= 24) {
          this.start_int = this.start;
        }
        return;
      }
      let newVal = this.start || this.startDateTime;
      if (!newVal) {
        return;
      }
      const parts = newVal.split("T");
      if (parts.length > 1) {
        newVal = parts[1];
      }
      var start = newVal.split(":")[0];
      this.start_int = Math.max(0, parseInt(start));
    },
    changed() {
      if (this.inner_digits === null || this.inner_digits === "") {
        return null;
      }
      var parts = this.inner_digits.split(":");
      var hour = parts[0];
      var hour12 = parseInt(hour);
      var ampm = "AM";
      if (hour12 >= 12 && hour12 < 24) {
        hour12 -= 12;
        ampm = "PM";
      }
      this.$emit("change", {
        HH: hour,
        hh: hour12,
        mm: parts[1],
        a: ampm,
        time: this.inner_digits,
      });
    },
    getProperHour(digits) {
      if (digits === null || digits === "null") {
        return "";
      }

      var parts = digits.split(":");
      var hour = parseInt(parts[0]);
      var mins = parts[1];
      digits = `${hour}:${mins}`;
      if (digits.length === 4) {
        digits = `0${digits}`;
      }
      return digits;
    },
    populateTimeValues() {
      this.time_values.splice(0);
      for (var index = this.start_int; index < this.end_int; index++) {
        var minuteGroups;
        if (index === this.end_int) {
          minuteGroups = ["00"];
        } else {
          minuteGroups = ["00", "30"];
        }
        for (var mIndex = 0; mIndex < minuteGroups.length; mIndex++) {
          var minute = minuteGroups[mIndex];
          var time = `${index}:${minute}`;
          if (time.length === 4) {
            time = `0${time}`;
          }

          var hour = index;
          var amPm = "a.m";
          if (hour >= 12) {
            if (hour > 12) {
              hour -= 12;
            }
            amPm = "p.m";
          }
          var timeString = `${hour}:${minute} ${amPm}`;
          var is30 = false;
          if (minute === "30") {
            is30 = true;
          }
          var tVal = {
            time: time,
            time_string: timeString,
            is_30: is30,
          };
          this.time_values.push(tVal);
        }
      }
    },
  },
  watch: {
    override(newVal) {
      var digits = this.getProperHour(newVal);
      if (digits === null || digits === "") {
        this.inner_digits = "";
      } else {
        this.inner_digits = digits;
      }
    },
    start() {
      this.readStart();
      this.populateTimeValues();
    },
    end(newVal) {
      if (!newVal || newVal === "") {
        return;
      }
      var end = newVal.split(":")[0];
      this.end_int = parseInt(end);
      this.populateTimeValues();
    },
    show_30s(newVal) {
      if (newVal) {
        this.inner_show_30s = newVal;
      }
    },
  },
  created() {
    this.readStart();
    this.populateTimeValues();
    if (this.show_30s === undefined || this.show_30s === null) {
      this.inner_show_30s = true;
    } else {
      this.inner_show_30s = this.show_30s;
    }

    var digits = this.override;
    if (digits) {
      digits = this.getProperHour(digits);
      this.inner_digits = digits;
    } else {
      this.inner_digits = "";
    }
  },
};
</script>
<template>
  <select
    ref="select"
    class="form-control timepicki"
    :id="id"
    v-model="inner_digits"
    @change="changed"
  >
    <option disabled value="">---Choose a time---</option>
    <template v-for="t_val in time_values">
      <template v-if="!t_val.is_30 || (t_val.is_30 && inner_show_30s)">
        <option :value="t_val.time" :key="t_val.time_string">
          {{ t_val.time_string }}
          <span class="short" v-if="getShortName(t_val.time)"
            >({{ getShortName(t_val.time) }})</span
          >
        </option>
      </template>
    </template>
  </select>
</template>

<style></style>
